'use client'

import Link from 'next/link'
import { SetStateAction, useState } from 'react'
import { signIn, useSession } from "next-auth/react";
import { useRouter, useSearchParams } from "next/navigation";


import Input from '@/components/ui/Input'
import Toast02 from '@/components/toast-02'
import { PasswordField } from '@/components/password-field';
import { Button } from '@/components/ui/button';
import { FRONTEND_URL } from '@/constants';

//#region to-do next build
    /**
     * Toda a pagina foi renderizada pelo cliente, buscar implementar a solucao abaixo: 
     * 
     * https://nextjs.org/docs/messages/deopted-into-client-rendering
     * 
     */
//#endregion

export default function SignInForm() {
    const router = useRouter();
    const searchParams =  useSearchParams();
    
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')


    // next auth tá retornando um erro sem sentido e encodando a url errado, fazendo a limpa
    let callbackUrl: string = ''
    const queryCallbackURL = decodeURIComponent(searchParams.get('callbackUrl') ?? FRONTEND_URL).replace('&error=SessionRequired', '');
    const [path, queryString] = queryCallbackURL.split('?');
    const newURLQueryString = new URLSearchParams(queryString)
    newURLQueryString.set('checkBills', 'true'); // set pois pode ser que o usuário saia, com checkBills já na url
    callbackUrl = `${path}?${newURLQueryString.toString()}`;
        
    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitting(true)

        await signIn('laravel', {
            email,
            password,
            device_name: window.navigator.userAgent,
            redirect: false, // o redirect faz com que os erros vão pra auth/error
            callbackUrl // precisa ter isso
        }).then( async res => {

            //#region to-do nextauth
                /**
                 * Bug no nextauth, esperando correcao
                 * https://github.com/nextauthjs/next-auth/issues/7725
                 * https://github.com/nextauthjs/next-auth/issues/7638
                 * 
                 * Não é possível confiar no ok e status (sempre virao true e 200)
                 * Para validar, usar o error , virá null se autenticado com sucesso
                 * 
                 */
            //#endregion

            if (res?.error) {
                setError(true)
                setMessage(`${res?.error}`)                
            } else {
                router.push(callbackUrl) // e isso também
                // em teoria no next auth vc precisaria apenas do callback, mas aqui está precisando ter os dois
            }
        }).finally(() => {
            setSubmitting(false)
        })

    }

    return(
        <form onSubmit={submitForm}>
            
            <div className="space-y-4">

                <Toast02 type="error" open={error} setOpen={setError}>
                    <div>{message}</div>
                </Toast02>    

                <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">Email</label>
                    
                    <Input
                        id="email"
                        type="email"
                        value={email}
                        className="form-input w-full"
                        onChange={(e: { target: { value: SetStateAction<string> } }) => setEmail(e.target.value)}
                        required
                        autoFocus
                        disabled={submitting}
                        autoComplete="email"
                    />

                </div>
                <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">Senha</label>

                    <PasswordField
                    autoComplete="current-password"
                    disabled={submitting} className="form-input w-full" password={password} setPassword={setPassword} />

                </div>
            </div>
            <div className="flex items-center justify-between mt-6">
            
                <div className="mr-1">
                    <Link className="text-sm underline hover:no-underline" href="/forgot-password">Esqueceu sua senha?</Link>
                </div>
                
                { /*<Link className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3" href="/">Sign In</Link> */}
                
                <Button 
                    loading={submitting} 
                    loadingText='Entrando...'
                    type="submit" 
                    aria-label="Sign In"
                >
                    Entrar
                </Button>

            </div>
        </form>
    )
}
